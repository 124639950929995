import { AriaCheckboxGroupItemProps, useCheckboxGroupItem } from "react-aria";
import React from "react";
import {
  StyledCheckbox,
  StyledLabel
} from "atom/checkbox/checkboxComponents";
import { CheckboxGroupContext } from "atom/checkbox/CheckboxGroupContext";

function Checkbox(props: AriaCheckboxGroupItemProps) {
  let { children } = props;
  let state = React.useContext(CheckboxGroupContext);
  let ref = React.useRef(null);
  let { inputProps } = useCheckboxGroupItem(props, state, ref);

  let isDisabled = state.isDisabled || props.isDisabled;
  let isSelected = state.isSelected(props.value);

  return (
    <StyledLabel data-disabled={isDisabled} data-selected={isSelected}>
      <StyledCheckbox {...inputProps} ref={ref} />
      {children}
    </StyledLabel>
  );
}

export default Checkbox;
